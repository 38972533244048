// Here you can add other styles

$lightBlue: #276ffa !default;
$DarkBlue:#0C0C35 !default;
$red: #ff808b !default;
$voilet: #871cd5 !default;
$blackText : #38221e !default;



// starting of header 
.wrapper{
    width: 100%;
    will-change: auto;
    transition: padding 0.15s;
    display: flex;
    flex-direction: column;
    background-color: #EBEEF1;
    .header{
        padding:0;
        background: #EBEEF1;
        border-bottom:none;
        & > .container-fluid{
            display: flex;
            flex-wrap: inherit;
            align-items: center;
            justify-content: space-between;
        }
        .headerMenu{
            background:$DarkBlue;
            min-height: 3rem;
            margin-bottom: 12px;
            button{
                color: #fff;
            }
            .header-nav{
                display: flex;
                flex-direction: row;
                margin-bottom: 0;
                list-style: none;
                padding-left: 0;
                .navbar-nav{
                    border-radius: 50px;
                    background: #fff;
                    padding: 0 15px;
                    input{
                        background-color: transparent;
                        border: none;
                        &:focus{
                            box-shadow: none;
                        }
                        &::placeholder{
                            color: $DarkBlue;
                        }
                    }
                    button{
                        background-color: #fff;
                        color: #0C0C35;
                        padding: 0.375rem 0.375rem;
                        line-height: 0;
                        border: none;
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
                .nav-item{
                    .nav-link{
                        color: #fff;
                        padding: 0rem 0.7rem;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        .icon{
                            cursor: pointer;
                        }
                        a{
                          color: #fff;
                          line-height: 0;
                        }
                        .avatar {
                            position: relative;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            vertical-align: middle;
                            border-radius: 50em;
                            transition: margin 0.15s;
                            width: 2rem;
                            height: 2rem;
                            font-size: 0.8rem;
                            .avatar-img {
                                width: 100%;
                                height: auto;
                                border-radius: 50em;
                            }
                        }
                        .avatar-md {
                            width: 2.2rem;
                            height: 2.2rem;
                            font-size: 1rem;
                        }
                    }
                }
            }
            .header-brand {
                padding-top: 0.3125rem;
                padding-bottom: 0.3125rem;
                font-size: 1.25rem;
                color:  #4f5d73;
                text-decoration: none;
                white-space: nowrap;
                img{
                    width: 110px;
                }
            }
            .header-toggler {
                padding: 0.25rem 0.75rem;
                font-size: 1.25rem;
                background-color:  transparent;
                border: 0;
                border-radius: 0.25rem;
                display: flex;
            }
            .dropdown{
                .dropdown-menu{
                    .dropdown-item{
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .profileSection{
                position: relative;
                width: 3rem;
                margin-left: auto;
                .userProfile{
                    width: 2.5rem;
                    height: 2.5rem;
                    overflow: hidden;
                    border-radius: 50%;
                    border:none;
                    padding: 0;
                    &:focus{
                        box-shadow: none;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
        .headerBreadcrumb{
            .breadcrumb {
                display: flex;
                flex-wrap: wrap;
                padding: 0 0;
                margin-bottom: 1rem;
                list-style: none;
                background-color:  unset;
                margin-bottom: 8px;
                .breadcrumb-item{
                    .active {
                        color: #8a93a2;
                    }
                }
            }
        }
    }
    .header-sticky {
        position: sticky;
        top: 0;
        z-index: 1029;
    }
}


// starting of side bar 
.sidebar{
        display: flex;
        flex: 0 0 15rem;
        flex-direction: column;
        order: -1;
        width: 15rem;
        padding: 0 0;
        color: rgba(255, 255, 255, 0.87);
        background: #3c4b64;
        box-shadow: none;
        transition: margin-left 0.15s, margin-right 0.15s, box-shadow 0.075s, transform 0.15s, width 0.15s, z-index 0s ease 0.15s;
        position: fixed;
        top: 0;
        bottom: 0;
        z-index: 1030;
        &:not(.sidebar-end) ~ * {
                padding-left: 15rem;
                @media screen and (max-width: 768px) {
                    padding-left: 0rem;
                }
            }
        @media screen and (max-width: 768px) {
            margin-left: -15rem;
        }
    .sidebar-brand{
        background-color: $DarkBlue;
        flex: 0 0 3rem;
        align-items: center;
        justify-content: center;
        .sidebar-brand-full{
            height: 40px;
        }
    }
    .sidebar-nav{
        background-color: #0C0C35;
        padding: 15px 10px 0;
        overflow-y: auto;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        .simplebar-wrapper{
            .simplebar-mask{
                .simplebar-offset{
                    .simplebar-content-wrapper{
                        .simplebar-content{
                            .nav-group{
                                list-style: none;
                                margin-bottom: 16px;
                                .nav-group-toggle{
                                    background-color: #0C0C35;
                                    color: #fff;
                                    display: flex;
                                    padding: 0.5rem 0rem;
                                    align-items: center;
                                    font-size: 13px;
                                    cursor: pointer;
                                    .nav-icon{
                                        color: #fff;
                                        height: 1.5rem;
                                        flex: 0 0 3rem;
                                    }
                                }
                                .nav-group-items{
                                    padding-left: 0;
                                    display: none;
                                }
                                .nav-group-items.show{
                                    display: block;
                                }
                            }
                            .nav-item{
                                list-style: none;
                                .nav-link{
                                    color: #fff;
                                    font-size: 13px;
                                    padding:0.445rem 1rem;
                                    display: flex;
                                    align-items: center;
                                    .nav-icon{
                                        color: #fff;
                                        height: 1.5rem;
                                        flex: 0 0 3rem;
                                    }
                                    &:hover{
                                        // background-color: $lightBlue;
                                        // color: #fff;
                                        box-shadow: inset 4px 0 0 #1885c9;
                                        color: #1885c9;
                                        // border-radius: 4px;
                                        .nav-icon{
                                            color: #1885c9;
                                        }
                                    }
                                    &.active{
                                        // background-color: $lightBlue;
                                        // border-radius: 4px;
                                       // color: #fff;
                                       box-shadow: inset 4px 0 0 #1885c9;
                                       color: #1885c9;
                                        .nav-icon{
                                            color: #1885c9;
                                        }
                                    }
                                }
                            }
                            .nav-title{
                                list-style: none;
                                color: $lightBlue;
                                padding: 0.445rem 1rem;
                                text-transform: capitalize;
                                font-weight: 500;
                                margin-top: 0.8rem;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

    }
}
.sidebar.sidebar-fixed.hide{
    margin-left: -15rem;
}
.sidebar.hide:not(.sidebar-end) ~ * {
    padding-left: 0;
}
.sidebar.show{
    margin-left: 0rem;
}
@media screen and (min-width: 768px) {
    .sidebar.sidebar-fixed:not(.sidebar-end) {
        left: 0;
    }
}


.bodyBackground{
    background-color: #EBEEF1;
}


// footer 
footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    min-height: 3rem;
    padding: 0.5rem 1rem;
    color: rgba(44, 56, 74, 0.95);
    background-color: #fff;
    border-top: 1px solid #d8dbe0;
}

.position-nav{
    position: relative;
    left: 12%;
}
.loader {
    margin: 15% 40%;      
    text-align: center;       
    // border: 16px solid #f3f3f3; /* Light grey */
    // border-top: 16px solid #3498db; /* Blue */
    // border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }

  .Sub-Category-table{
    .ant-table-tbody{
        .ant-table-cell{
            padding-right: 235px;
        }
    }
  }
.image-label{
    color:$DarkBlue;
    font-weight: 500;
    padding-left: 10px;
}
.table{
.ant-pagination-total-text { 
    text-align: center;
    list-style: none;
    padding: 0px 15px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    position: absolute;
    right: 5%;
  }
}
.load-more-button{
    text-align: center;
    padding: 10px 0;
    .ant-pagination-prev ,.ant-pagination-next,
        .ant-pagination-disabled{
            height: 40px;
        }
}