@import "./Common.scss";

// form style 
.align-item-center{
    align-items: center;
}

.headingSection{
    margin-bottom: 16px;
    .heading{
        h6{

        }
    }
    .btnGroup{
        text-align: right;
        button{
            margin-left: 10px;
        }
    }
}

.form-elements-wrapper{
    .customRow{
        .col16{
            &:nth-child(1){
                .ant-form-item{
                    margin-left: 0;
                }
            }
        }
        .col10{
            &:nth-last-child(1){
                .ant-form-item{
                    margin-right: 0;
                }
            }
        }
        .col10{
            width: 10%;
            flex-basis: 10%;
            button{
                width: 100%;
            }
        }
        .col16{
            width: 16%;
            flex-basis: 16%;
        }
        .col20{
            width: 20%;
            flex-basis: 20%;
        }
        .col26{
            width: 26.6%;
            flex-basis: 26.6%;
        }

    }
    .ant-form{
        display: flex;
        align-content: center;
        
        .ant-form-item{
            margin: 6px;
        }
        input, .ant-select-selector{
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5!important;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem!important;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
        .textEditor{
            display: block;
            width: 100%;
            padding: 0.375rem;
            font-size: 1rem;
             line-height: 1.5!important;
            height: 300px;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem!important;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            .quill{
                .ql-container{
                    height: 242px;
                }
            }
        }
        .ant-select-selector{
            height: 38px!important;
            .ant-select-selection-placeholder{
                line-height: 24px;
            }
            input{
                height: 38px!important;
            }
            &:after {
                line-height: 15px!important;
            }
            ::placeholder{
                color: #495057;
            }
        }
        .ant-upload.ant-upload-select{
            width: 100%;
            button{
                width: 100%;
                text-align: left;
            }
        }
        button{
            height: 38px;
            border-radius: 0.25rem!important;
        }
        .submit-btn{
            margin: 24px 0;
        }
    }
}



.table-elements-wrapper{
    // margin: 32px 0;
    .ant-table-wrapper{
        .ant-table{
            .ant-table-container{
                .ant-table-content{
                    table{
                        .ant-table-tbody{
                            .ant-table-row{
                                .ant-table-cell{
                                    .action{
                                        position: relative;
                                        .icon{
                                            cursor: pointer;
                                        }
                                        .popover-menu{
                                            position: absolute;
                                            background: #fff;
                                            box-shadow: 0px 0px 5px 1px #ccc;
                                            border-radius: 4px;
                                            display: none;
                                            z-index: 99;
                                            &.show{
                                                display: block;
                                            }
                                            .popover-item{
                                                padding: 4px 5px;
                                                font-size: 12px;
                                                cursor: pointer;
                                                display: flex;
                                                &:nth-child(1){
                                                    border-bottom: 1px solid #ccc;
                                                }
                                                .icons{
                                                    font-size: 16px;
                                                    margin-right: 4px;
                                                }
                                                .EditIcon{
                                                    color: #276ffa;
                                                }
                                                .deleteIcon{
                                                    color: #ff808b;
                                                }
                                                .viewIcon{
                                                    color: #276ffa;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.ant-table-row-expand-icon-cell{
    button{
        height: 17px!important;
    }
}

.selectCategories{
    margin-top: 40px;
    .selectCategoriesCol{
        p{
            margin-bottom: 0;
            span{
                color: $secondary-color;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }
}